.picker {
  // scss-docs-start picker-css-vars
  --#{$variable-prefix}picker-footer-border-top: #{$picker-footer-border-width} solid #{$picker-footer-border-color};
  --#{$variable-prefix}picker-footer-padding: #{$picker-footer-padding};
  // scss-docs-end picker-css-vars

  .dropdown-menu {
    padding: 0;
  }

  &.show {
    .input-group {
      color: var(--#{$variable-prefix}input-focus-color, $input-focus-color);
      background-color: var(--#{$variable-prefix}input-focus-bg, $input-focus-bg);
      border-color: var(--#{$variable-prefix}input-focus-border-color, $input-focus-border-color);
      outline: 0;
      @include border-radius($input-border-radius, 0);
      @if $enable-shadows {
        @include box-shadow($input-box-shadow, $input-focus-box-shadow);
      } @else {
        // Avoid using mixin so we can pass custom focus shadow properly
        box-shadow: $input-focus-box-shadow;
      }
    }
  }
}

.picker-input-group {
  &:hover {
    .form-control:not(:placeholder-shown) ~ .input-group-text {
      .picker-input-group-indicator:not(:last-child) {
        display: none;
      }
      .picker-input-group-cleaner {
        display: initial;
      }
    }
  }

  .form-control {
    &.hover {
      color: var(--cui-gray-500);
    }

    &:focus {
      border-color: var(--#{$variable-prefix}input-border-color, $input-border-color);
      outline: 0;
      box-shadow: none;
    }

    &:not(:first-child) {
      @include ltr-rtl("border-left", 0);
    }

    &:not(:last-child) {
      @include ltr-rtl("border-right", 0);
    }

    &[readonly] {
      background-color: var(--#{$variable-prefix}input-bg, $input-bg);
    }

    &.form-control:disabled + .input-group-text {
      background-color: var(--#{$variable-prefix}input-disabled-bg, $input-disabled-bg);
    }
  }

  .input-group-lg {
    .picker-input-group-icon {
      width: 1.25rem;
      height: 1.25rem;
      font-size: 1.25rem;
    }
  }

  .input-group-sm {
    .picker-input-group-icon {
      width: .875rem;
      height: .875rem;
      font-size: .875rem;
    }
  }

  .input-group-text {
    color: var(--cui-gray-400);
    background-color: var(--#{$variable-prefix}input-bg, $input-bg);
  }
}

.picker-input-group-cleaner {
  display: none;
}

.picker-input-group-icon {
  display: block;
  width: 1rem;
  height: 1rem;
  font-size: 1rem;
  @include transition(background-image .15s ease-in-out);
}

.picker-footer {
  display: flex;
  justify-content: flex-end;
  padding: var(--#{$variable-prefix}picker-footer-padding);
  border-top: var(--#{$variable-prefix}picker-footer-border-top);

  .btn + .btn {
    @include ltr-rtl("margin-left", .5rem);
  }
}
