@mixin elevation($value, $exclude: null) {
  @if $enable-elevations {
    @if $exclude == null {
      &:not([class*="border"]) {
        border: 0;
      }
      box-shadow: map-get($elevations, $value);
    }
    @else {
      $selector: ":not(.placeholder)";
      @each $element in $exclude {
        $selector: selector-append($selector, ":not(#{$element})");
      }
      @if ($selector != ":not(.aaa") {
        &#{$selector} {
          border-color: transparent;
          box-shadow: map-get($elevations, $value);
        }
      }
    }
  }
}
