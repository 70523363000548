// List Groups
// scss-docs-start list-group-mixin
@mixin list-group-item-variant($state, $variant, $theme-prefix: false) {
  $background: map-get($variant, "bg");
  $background-hover: map-get($variant, "bg-hover");
  $color: contrast-ratio-correction(map-get($variant, "color"), map-get($variant, "bg"), abs($alert-color-scale), $state);

  $selector: if($theme-prefix, theme-prefix("list-group-item-#{$state}"), ".list-group-item-#{$state}");

  #{$selector} {
    --#{$variable-prefix}list-group-color: #{$color};
    --#{$variable-prefix}list-group-bg: #{$background};
    --#{$variable-prefix}list-group-hover-bg: #{$background-hover};
    --#{$variable-prefix}list-group-action-hover-color: #{$color};
    --#{$variable-prefix}list-group-action-active-color: #{$white};
    --#{$variable-prefix}list-group-action-active-bg: #{$color};
    --#{$variable-prefix}list-group-action-active-border-color: #{$color};
  }
}
// scss-docs-end list-group-mixin
